import {AppProps} from "next/app"
import Head from "next/head"
import {NextUIProvider} from "@nextui-org/react"
import {withTRPC} from "@trpc/next"
import PlausibleProvider from "next-plausible"

import {darkTheme, lightTheme} from "theme"
import ErrorCapturer from "components/ErrorCapturer"
import {config as trpcConfig} from "datasources/trpc"

import "styles/fonts.css"
import "styles/globals.css"
import env from "env/client"

const App = ({Component, pageProps, router}: AppProps) => {
  const isEditor = router.pathname.startsWith("/editor")
  const theme = isEditor ? darkTheme : lightTheme

  return (
    <PlausibleProvider
      domain={env.plausibleUrl}
      trackOutboundLinks
      enabled={!!env.plausibleUrl}
    >
      <ErrorCapturer>
        <NextUIProvider {...{theme}}>
          <Head>
            <title>Debrief.</title>
          </Head>
          <Component {...pageProps} />
        </NextUIProvider>
      </ErrorCapturer>
    </PlausibleProvider>
  )
}

export default withTRPC(trpcConfig)(App)
export {reportWebVitals} from "next-axiom"
