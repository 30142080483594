import {createTheme} from "@nextui-org/react"

// prettier-ignore
const fonts = [
  "ui-sans-serif",
  "-apple-system",
  "BlinkMacSystemFont",
  "\"Segoe UI\"",
  "Helvetica",
  "\"Apple Color Emoji\"",
  "Arial",
  "sans-serif",
  "\"Segoe UI Emoji\"",
  "\"Segoe UI Symbol\""
]

const baseTheme = {
  fonts: {
    sans: fonts.join(", "),
    serif: "'Crimson Text', sans-serif"
  },
  borderWeights: {
    normal: "1px"
  },
  colors: {
    white: "#fff",
    black: "#000"
  }
}

const darkTheme = createTheme({
  type: "dark",
  theme: baseTheme
})

const lightTheme = createTheme({
  type: "light",
  theme: baseTheme
})

export {darkTheme, lightTheme}
